<template>
	<div>
		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<div
			v-if="!loading"
			class="container-md"
		>
			<user v-model="user"></user>

			<div class="flex align-start">
				<a
					href="#"
					class="btn"
					@click="saveUser()"
				>
					Rætta
				</a>

				<a
					v-if="hasTwoFactor"
					href="#"
					@click="disableTwoFactor"
					class="btn background-red ml-10"
				>
					Nullstilla 2FA
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import User from '@/components/forms/User.vue';
import axios from 'axios';

export default {
	name: 'UserEdit',

	components: { User },

	data() {
		return {
			loading: true,

			userId: null,
			hasTwoFactor: false,
			user: {},
		};
	},

	mounted() {
		this.userId = this.$route.params.id;

		axios
			.get(`/users/${this.userId}`)
			.then((response) => {
				this.user = response.data;
				this.hasTwoFactor = !!this.user.twoFactorVerifiedAt;
			})
			.catch((error) => {
				console.log('Error fetching user', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		saveUser() {
			delete this.user.password_confirm;

			axios
				.patch(`/users/${this.userId}`, this.user)
				.then((response) => {
					console.log('response', response);

					this.$router.push({ name: 'Users' });
				})
				.catch((error) => {
					console.log('Error fetching user', error);
				});
		},

		disableTwoFactor() {
			axios
				.delete(`/users/${this.userId}/2fa`)
				.then(() => {
					this.$router.push({ name: 'Users' });

					console.log('Test');
				})
				.catch((error) => {
					console.log('Error fetching user', error);
				});
		},
	},
};
</script>
